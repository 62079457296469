import {request} from '@/utils/axios'

// 品种产量
// 品种列表
export const getPlantsList = () => {
    return request({
        method: 'get',
        url: `${sysUrl}/web/plantVarietiesInfo/listAll`
    })
}

// 某个品种某个月的产量、面积合计
export const getPlantTotalInfo = params => {
    return request({
        method: 'get',
        url: `${sysUrl}/industryMap/variety/countAreaAndYieldByPlantAndMonth`,
        params
    })
}

// 某个品种未来6个月产量
export const getPlantYield = params => {
    return request({
        method: 'get',
        url: `${sysUrl}/industryMap/variety/selectFuture6MonthsTrend`,
        params
    })
}

// 获取12地市面积及产量分布
export const getPlantDistribution = params => {
    return request({
        method: 'get',
        url: `${sysUrl}/industryMap/variety/selectPlantCityYieldAndAreaList`,
        params
    })
}

// 地图区域查询
export const getMapAreaInfo = params => {
    return request({
        method: 'get',
        url: `${sysUrl}/industryMap/variety/selectListByVarAndCity`,
        params
    })
}

// 企业分布
// 统计(企业类型分布)
export const getCompanyInfo = ({ids, areaCode}) => {
    return request({
        method: 'post',
        url: `${sysUrl}/business/companyType/getStatisticsByTypeIds?ids=${ids}&areaCode=${areaCode}`
    })
}

// 企业各市分布柱状图
export const getCompanyCount = params => {
    return request({
        method: 'get',
        url: `${sysUrl}/business/company/getCompanyCountByAreaCode`,
        params
    })
}

// 点击类型返回企业详情
export const getCompanyList = params => {
    return request({
        method: 'get',
        url: `${sysUrl}/business/company/list`,
        params
    })
}

// 企业类型列表
export const getCompanyType = () => {
    return request({
        method: 'get',
        url: `${sysUrl}/business/companyType/selectTypeTreeList`
    })
}