<template>
    <div class="zhsc">
        <component :is="curr"></component>
        <div class="menu">
            <ul>
                <li :class="{active:curr==item.url}" @click="tabChange(item)" v-for="item in navList" :key="item.title">{{item.title}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import qyfb from './qyfb'
import spjc from './spjc'
import { destory } from '@/utils/video'
import { spjcDestory } from '@/utils/video1'

export default {
    name:'Zhsc',
    components: { qyfb, spjc },
    data(){
        return {
            curr:'qyfb',
            navList:[
                {
                    title:'企业分布',
                    url:'qyfb',
                },
                {
                    title:'视频监测',
                    url:'spjc',
                }
            ]
        }
    },
    mounted(){

    },
    methods:{
        tabChange(obj) {
            this.curr = obj.url
            destory()
            spjcDestory()
        }
    }
}
</script>
<style lang='scss' scoped>
    .zhsc{
        width: 100%;
        height: 100%;
        position: relative;
        .menu{
            position: absolute;
            top: 25px;
            left: 150px;
            ul{
                // border: 1px solid #f00;
                display: flex;
                li{
                    width: 175px;
                    height: 51px;
                    font-size: 24px;
                    color: #32EEFF;
                    text-align: center;
                    line-height: 0;
                    background: url('~@/assets/images/gk_nav.png') no-repeat center/100% 100%;
                    cursor: pointer;
                }
                li:nth-child(2){
                    margin: 0 45px;
                }
                .active{
                    font-weight: bold;
                    color: #fff;
                    background: url('~@/assets/images/gk_nav_active.png') no-repeat center/100% 100%;
                }
            }
        }
    }
</style>