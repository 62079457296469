<template>
  <div class="qyfb">
    <div class="left">
      <map-chart
        ref="companyMapRef"
        height="760px"
        style="margin-top: 90px"
        :chart-option="chartOption"
        has-drop
        @selectCity="selectCity"
        @selectMarker="selectMarker"
      ></map-chart>
      <div class="tolink" v-if="cityCode == 131100000000" @click="toLink">进入市平台</div>
      <div class="staticList">
        <div class="item" v-for="(item,index) in staticList" :key="index">
          <div class="label">{{item.label}}</div>
          <div class="value">{{item.value}}<span style="font-size: 16px">家</span> </div>
        </div>
      </div>
      <div class="pub_legend">
        <div class="legend_title">图例</div>
        <ul class="legend_list">
          <li v-for="item in legendData" :key="item.lablename">
            <el-checkbox v-model="item.check" class="pub_checkbox" @change="selectChange($event,item)"></el-checkbox>
            <img :src="item.icon" alt=""> {{ item.lablename }}
          </li>
          <!-- <li>
              <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
              <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 化肥企业
          </li>
          <li>
              <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
              <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 种子种苗
          </li>
          <li>
              <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
              <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 农药销售
          </li>
          <li>
              <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
              <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 生产器械
          </li>
          <li>
              <el-checkbox v-model="checked" class="pub_checkbox"></el-checkbox>
              <img src="@/assets/images/cygk/zdxm_icon.png" alt=""> 检验检测
          </li> -->
        </ul>
      </div>
    </div>
    <div class="right">
      <div class="title">
        {{companyInfo.name}}
      </div>
      <div class="inner-left">
        <div class="top pub_border">
          <div class="pub_title">环境采集信息</div>
          <div class="environment-info">
            <img @click="arrowClick('cardShow', 'left')" src="./images/left-arrow.png" class="arrow left-arrow" alt="" v-show="realData.length > 1">
            <el-carousel ref="cardShow" height="80px" :autoplay="false" arrow="never" @change="envDeviceChange">
              <el-carousel-item v-for="(item,index) in realData" :key="index" v-if="realData.length">
                <div class="inner">
                  <div class="item" v-for="env in item.data.slice(0, 5)" :key="env.envparamid" @click="envDeviceChange(env)">
                    <span>{{env.name}}</span>
                    <span>{{`${env.envvalue}${env.unit}`}}</span>
                  </div>
                </div>
              </el-carousel-item>
              <el-carousel-item v-if="!realData.length"><div class="inner">暂无数据</div></el-carousel-item>
            </el-carousel>
            <img @click="arrowClick('cardShow', 'right')" src="./images/right-arrow.png" class="arrow right-arrow" alt="" v-show="realData.length > 1">
          </div>
          <div class="chart" id="envChart"></div>
        </div>
        <div class="bottom pub_border">
          <div class="pub_title">摄像点位</div>
          <div class="video-wrapper">
            <el-scrollbar class="video-list">
              <div class="video-item"
                   :class="{'active': curVideo === item.id}"
                   v-for="item in videoData"
                   :key="`videoData${item.id}`"
                   :title="item.name"
                   @click="preview(item)"
              >{{item.name}}</div>
            </el-scrollbar>
            <div id="playWnd" v-if="aaaa" class="video"></div>
          </div>
        </div>
      </div>
      <div class="inner-right">
        <div class="pub_border">
          <div class="pub_title">企业基本情况</div>
          <div class="company-info">
            <div class="info-item">
              <img src="./images/icon-area.png"/>
              <span>种植面积</span>
              <span>{{companyInfo.plantArea}}亩</span>
            </div>
            <div class="info-item">
              <img src="./images/icon-person.png"/>
              <span>负责人</span>
              <span>{{companyInfo.contacts}}</span>
            </div>
            <div class="info-item">
              <img src="./images/icon-variety.png"/>
              <span>主要品种</span>
              <span>{{companyInfo.varieties}}</span>
            </div>
            <div class="description">
              {{companyInfo.introduce}}
            </div>
            <div class="company-image">
              <img @click="arrowClick('company', 'left')" src="./images/left-arrow.png" class="arrow left-arrow" alt="">
              <el-carousel ref="company" height="205px" :autoplay="false" arrow="never">
                <el-carousel-item v-for="item in companyImage" :key="item.id">
                  <div class="inner">
                    <img :src="`${imageUrl}${item.imgUrl}`" style="width: 90%">
                  </div>
                </el-carousel-item>
              </el-carousel>
              <img @click="arrowClick('company', 'right')" src="./images/right-arrow.png" class="arrow right-arrow" alt="">
            </div>
            <div class="company-device">
              <div class="device-item">
                <img src="./images/icon-weater.png"/>
                <div>
                  <span>气象站</span>
                  <span>{{atmoData.length}}套</span>
                </div>
              </div>
              <div class="device-item">
                <img src="./images/icon-env.png"/>
                <div>
                  <span>环境采集设备</span>
                  <span>{{envData.length}}套</span>
                </div>
              </div>
              <div class="device-item">
                <img src="./images/icon-cabinet.png"/>
                <div>
                  <span>智能控制柜</span>
                  <span>{{cabinetData.length}}套</span>
                </div>
              </div>
              <div class="device-item">
                <img src="./images/icon-video.png"/>
                <div>
                  <span>视频采集</span>
                  <span>{{videoData.length}}套</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { getCompanyList} from '@/api/cyfb'
import { getOrgInfoList } from "@/api/zhsc"
import { getCompanyDetail, getCompanyImage, getDeviceInfoByCompany, getDeviceRealData, getDeviceOldData } from '@/api/zhsc'
import mapChart from '../../components/map/mapCopy'
import { parseTime } from '@/utils/date'
import { initPlugin, preview, stopPreview } from '@/utils/video'
import { ERR_OK, SUCCESS } from '../../utils/config'

const PARENT_CODE = '130000000000'

export default {
    components: { mapChart },
    data() {
        return {
          aaaa: true,
          num: 0,
          staticList: [
            {label:'企业总数',value: 0},
            {label:'智能化企业',value: 0},
          ],
          legendData: [//产业企业分布
            {lablename:'传统企业', icon: require('../../assets/images/qyfb_marker.png'), check:true,children: []},
            {lablename:'智能化企业', icon: require('../../assets/images/zhqy_marker.png'), check:true,children: []}
          ],
            imageUrl: sysUrl,
            companyInfo: {},
            companyImage: [],
            deviceData: [],
            videoData: [],
            curVideo: '',
            atmoData: [], // 气象
            cabinetData: [], // 控制柜
            envData: [], // 监测
            realData: [], // 气象设备实时数据
            cityCode: PARENT_CODE,
            chartOption: {
                layoutSize: '125%',
//                tooltip: (params) => {
//                    let data = params.data
//                    let html = `<div style="padding:0;width: 280px;height: 120px;background: rgba(2,18,81,0.68);">
//                        <div style="width: 254px;height: 23px;line-height: 23px;text-indent: 1em;font-size: 13px;color: #fff;background: linear-gradient(90deg,rgba(45,125,255,1), rgba(45,125,255,0))">${data.name}</div>
//                        <div style="margin-left: 1em;line-height: 30px"><span>联系人：<span style="color:#0CFED9;font-size: 18px">${data.contacts || ''}</span></span></div>
//                        <div style="margin-left: 1em;line-height: 30px"><span>联系电话：<span style="color:#0CFED9;font-size: 18px">${data.phone || ''}</span></span></div>
//                        <div style="margin-left: 1em;line-height: 30px"><span>主要品种：<span style="color:#0CFED9;font-size: 18px">${data.varieties || ''}</span>吨</span></div>
//                    </div>`
//                    return html
//                }
            },
        }
    },
    mounted() {
        this.init()
        this.initEnvChart()
        let scale = this.getScale()
        initPlugin({
            width: 536 * scale,
            height: 330 * scale
        })
    },
    methods: {
        getScale() {
            let wh = window.innerHeight / 1080;
            let ww = window.innerWidth / 1920;
            return ww < wh ? ww : wh;
        },
        // 数据初始化
        async init() {

          this.num = 0
            await this.getCompanyList()
        },
      selectChange(val,row){
        var data = this.legendData?.filter(item => item.check == true)?.map(item => item.children)?.flat(Infinity)??[]
        console.log(data,'data-------')
        this.$refs.companyMapRef.addMarker(data)
      },
        // 获取所有企业上图
        getCompanyList() {
           getOrgInfoList({
            industryId:'2862999118ed4dc296b42fe8fecdd301',
            areaCode: this.cityCode
          }).then(res => {
             var data = res?.data?.map(item => {
               item.longitude = item.location.length> 0 ? (JSON.parse(item?.location??'{}')?.lng??'') : ''
               item.latitude = item.location.length> 0 ? (JSON.parse(item?.location??'{}')?.lat??'') :""
               return item
             })?.map(item => {
               return {
                 name: item.orgName,
                 url: `image://${require('@/assets/images/qyfb_marker.png')}`,
                 size: [39, 51],
                 data: [{
                   value: [item.longitude, item.latitude],
                   id: item.orgInfoId,
                   name: item.orgName,
                   ...item
                 }]
               }
             })
             var marker = data?.filter(item => !item.name.includes('京津冀蔬菜'))?.filter(item => !item.name.includes('高村春光'))?.filter(item => !item.name.includes('大尹村镇设施农'))??[]
             var marker1 = []
             data?.forEach(item => {
               if(item.name.includes('京津冀蔬菜') ||  item.name.includes('高村春光')|| item.name.includes('大尹村镇设施农')) {
                 marker1.push({
                   ...item,
                  url: 'image://' + require('./../../assets/images/map_marker.png'),
                 })
               }
             })??[]
             this.legendData[0].children = [...marker,...marker1]?.filter(item => ((item?.data??[{}])[0]?.longitude??'').toString().length>0 && ((item?.data??[{}])[0]?.latitude??'').toString().length>0)??[]

             this.staticList[0].value =this.legendData[0].children.length
                 this.selectChange()
           })
          this.getCompanyone()
        },
      getCompanyone(){
        getCompanyList({
                parentAreaCodes: this.cityCode,
                pageSize: 10000,
                intDevice: 1,
            }).then(res => {
                if (res.code === ERR_OK) {
                  let marker = []
                  res.rows.forEach(item => {
                    let obj = {
                      name: item.name,
                      url: `image://${require('@/assets/images/zhqy_marker.png')}`,
                      size: [39, 51],
                      data: [{
                        value: [item.longitude, item.latitude],
                        //                                    value: [119.596224, 39.943458],
                        ...item
                      }]
                    }
                    marker.push(obj)
                  })
                  /*var data = res?.rows?.filter(item => !item.name.includes('京津冀蔬菜'))?.filter(item => !item.name.includes('高村春光'))?.filter(item => !item.name.includes('大尹村镇设施农'))??[]
                  var data1 = []
                  res?.rows?.forEach(item => {
                    if(item.name.includes('京津冀蔬菜') ||  item.name.includes('高村春光')|| item.name.includes('大尹村镇设施农')) {
                      data1.push(item)
                    }
                  })??[]
                  var marker = data?.map(item => {
                    return {
                      name: item.name,
                      url: 'image://' + require('./../../assets/images/qyfb_marker.png'),
                      size: [39, 51],
                      type: 1,
                      data: [{
                        value: [item.longitude, item.latitude],
                        ...item
                      }]
                    }
                  })
                  var marker1 = data1?.map(item => {
                    return {
                      name: item.name,
                      url: 'image://' + require('./../../assets/images/map_marker.png'),
                      size: [39, 51],
                      type: 2,
                      data: [{
                        value: [item.longitude, item.latitude],
                        ...item
                      }]
                    }
                  })
                  this.legendData[0].children = [...marker1,...marker]?.filter(item => item.name)?.filter(item => ((item?.data??[{}])[0]?.longitude??'').length>0 && ((item?.data??[{}])[0]?.latitude??'').length>0)??[]*/
                  this.legendData[1].children = marker?.filter(item => item.name)?.filter(item => ((item?.data??[{}])[0]?.longitude??'').length>0 && ((item?.data??[{}])[0]?.latitude??'').length>0)??[]
                  this.staticList[1].value =this.legendData[1].children.length
                  this.selectChange()
                  console.log(res.rows,'                    res.rows[0].\n')
                    //默认打开张家口弘基\
                  if(this.num == 0) {
                    this.selectMarker({id:'fee5d9490bd94017a46f64364a19ef21'})
                  } else {
                    var ids = (res?.rows??[{}])[0]?.id??null
                    if(ids) {
                      this.selectMarker({id:this.cityCode == 131100000000? '523caf8c5d5e4904ae47b8f91a4adf56': res.rows[0].id})
                    }
                  }
                }
            })
      },
        // 标注选择
        async selectMarker(obj) {
            await this.getCompanyDetail(obj.id)
            this.getCompanyImage(obj.id)
            console.log(this.companyInfo.name);
            this.getDeviceInfoByCompany(this.companyInfo.name)
          if(this.companyInfo.name.includes('京津冀蔬菜科') || this.companyInfo.name.includes('高村春光')) {
            window.open(`http://47.94.107.242:35015/digitalEnterprise/#/homes?orgid=${this.companyInfo.id}&areacode=${this.companyInfo.areaCode.toString().substring(0,6)}&shortname=${this.companyInfo.name}&title=数字企业&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI4ZjY0ODE0MGY2MThmYjk1OTk1OTQ4ZjU5MDEwNTI2NCIsInVzZXJuYW1lIjoi6KGh5rC05biC5YWo5Lqn5Lia6ZO-566h55CG5ZGYIn0.DKXYI-PfCd-BWDe_nFS-57Lp02GSaepHgCfs-hXtK38`,'_blank')
          }
          if(this.companyInfo.name.includes('大尹村镇设施')) {
             window.open(`https://www.720tl.com/t/0b4cfc3361ada6f6`,'_blank')
          }
        },
      toLink(){
          window.open('http://industrycity.platform.hbyzc.com.cn/#/mainbodyOverview?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI4ZjY0ODE0MGY2MThmYjk1OTk1OTQ4ZjU5MDEwNTI2NCIsInVzZXJuYW1lIjoi6KGh5rC05biC5YWo5Lqn5Lia6ZO-566h55CG5ZGYIn0.DKXYI-PfCd-BWDe_nFS-57Lp02GSaepHgCfs-hXtK38&areaCode=131100000000&industryId=2862999118ed4dc296b42fe8fecdd301','_blank')
      },
        selectCity(obj) {
            this.cityCode = obj.code || PARENT_CODE
          console.log(this.cityCode,'cityCode')
          this.num = this.cityCode == 130000000000? 0 : 1
          this.$nextTick(() => {
            this.getCompanyList()
            this.getDeviceInfoByCompany(this.companyInfo.name,this.cityCode)
          })
        },
        // 获取企业详情
        getCompanyDetail(id) {
            return getCompanyDetail(id).then(res => {
                if (res.code === ERR_OK) {
                    this.companyInfo = res.data
                }
            })
        },
        // 获取企业图片
        getCompanyImage(id) {
            return getCompanyImage(id).then(res => {
                if (res.code === ERR_OK) {
                    this.companyImage = res.rows
                }
            })
        },
        // 获取企业设备数量
        getDeviceInfoByCompany(name,row) {
            return getDeviceInfoByCompany({
                // companyName: name,
              industry:"蔬菜",
              areaCode: this.cityCode == 130000000000 ? 13 : this.cityCode?.substring(0,4)??13
//                companyName: '南和县三多蔬菜专业合作社'
            }, {
                autp: 2,
                token: window.sessionStorage.token
            }).then(res => {
                if (res.type === SUCCESS) {
                    this.deviceData = res.data
                  this.aaaa = false
                    this.videoData = res.data.filter(item => item.devicetype === 'video')
                    // 气象和采集
                    this.atmoData = res.data.filter(item => item.devicetype === 'atmo')
                    this.sensorData = res.data.filter(item => item.devicetype === 'sensor')
                    // 控制柜
                    this.cabinetData = res.data.filter(item => item.devicetype === 'cabinet')
                    // 环境监测
                    this.envData = res.data.filter(item => item.devicetype ===  ('pests' || 'spore' || 'sensor'))

                    stopPreview()
                  this.aaaa = true
                    this.$nextTick(() => {
                        // 加载实时视频
                        this.videoData.length && this.preview(this.videoData[0])
                    })
                    // 获取环境设备实时数据
                    this.getDeviceRealData([...this.atmoData.slice(0,2), ...this.sensorData.slice(0,2)])
                }
            })
        },
        // 获取环境设备实时数据
        getDeviceRealData(data) {
            if (!data.length) {
                this.realData = []
                this.envChartReload({}, [])
            }
            data.forEach(async (item, index) => {
                let param = {
                    deviceId: item.id
                }
                const res = await getDeviceRealData({
                    data: JSON.stringify(param)
                }, {
                    autp: 2,
                    token: window.sessionStorage.token
                })
                if (res.type === SUCCESS) {
                    let obj = {
                        deviceId: item.id
                    }
//                    let arr = ['空气温度', '空气湿度', '气压', '风向', '风速']
                    this.realData.push({
                        data: res.data[obj.deviceId]
                    })
                    if (index === 0) {
                        // 获取设备历史数据
                        this.envDeviceChange(this.realData[0].data[0])
                    }
                }
            })
        },
        // 环境设备切换获取设备历史数据
        envDeviceChange(data) {
            if (data) {
                const format = '{y}-{m}-{d}'
                let today = parseTime(Date.now(), format)
                let recentDay = parseTime(new Date(today) - 6 * 24 * 60 * 60 * 1000, format)
                let param = {
                    stime: `${recentDay} 00:00:00.000`,
                    etime: `${today} 23:59:59.999`,
                    device: [{
                        deviceId: data.deviceid,
                        envParamId: data.envparamid
                    }],
                    page: 1,
                    limit: 10000
                }
                getDeviceOldData({
                    data: JSON.stringify(param)
                }, {
                    autp: 2,
                    token: window.sessionStorage.token,
                    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }).then(res => {
                    if (res.type === SUCCESS) {
                        this.envChartReload(data, res.data.data)
                    }
                })
            }
        },
        // 加载实时视频
        preview(obj) {
            this.curVideo = obj.id
            preview(obj.terminal)
//            preview('0e7f14aaa7a744118d15d9045e45beef')
        },
        initEnvChart(){//环境信息
            this.envChart = this.$echarts.init(document.getElementById('envChart'));
//            let dataC1 = [20, 30, 60, 40, 50, 30];
//            let dataC2 = [10, 20, 50, 30, 40, 20];
//            let dataC3 = [40, 30, 40, 20, 80, 10];
//            let xData = ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00'];
//            let legendData = ['土壤温度','空气温度','光照'];
            let legendData = ['土壤温度'];
            let fontColor = '#B9C3D3';
            let option = {
                backgroundColor: 'transparent',
                grid: {
                    left: '10%',
                    right: '2%',
                    top: '14%',
                    bottom: '15%',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                },
                legend: {
                    data: legendData,
                    right: '8%',
                    top: '2%',
                    textStyle: {
                        color: fontColor,
                    },
                },
                xAxis: {
                    type: 'category',

                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                    offset: 0,
                    axisTick: {
                        show: false,
                        alignWithLabel: true,
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                    axisLabel: {
                        fontSize: 16,
                        color: fontColor
                    },
                    data: [],
                },
                yAxis: {
                    name: '土壤温度',
                    nameTextStyle:{
                        color: fontColor,
                    },
                    type: 'value',
//                    min: 0,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dotted',
                            color: fontColor,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 16,
                        color: fontColor
                    },
                    boundaryGap: ['20%', '20%'],
                },
                series: [
                    {
                        name: legendData[0],
                        type: 'line',
//                        smooth: false,
                        step: 'start',
                        symbol: 'none',
                        showSymbol: false,
                        symbolSize: 8,
                        itemStyle: {
                            color: '#00A8FF',
                        },
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(0,168,255,0.4)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(0,168,255,0)'
                                }
                            ])
                        },
                        data: [],
                    },
                    {
                        name: legendData[1],
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        showSymbol: false,
                        symbolSize: 8,
                        itemStyle: {
                            color: '#0BFFD5',
                        },
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(11,255,213,0.4)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(11,255,213,0)'
                                }
                            ])
                        },
                        data: [],
                    },
                    {
                        name: legendData[2],
                        type: "effectScatter",
                        symbolSize: [5, 5],
                        rippleEffect: {
                            period: 1,
                            scale: 8,
                            brushType: "fill",
                        },
                        itemStyle: {
                            color: new this.$echarts.graphic.RadialGradient(0.4, 0.3, 1, [
                                {
                                    offset: 0,
                                    color: "#00FFF2",
                                },
                                {
                                    offset: 1,
                                    color: "#00E4FF",
                                },
                            ]),
                        },
                        data: [],
                    },
                ],
            };
            this.envChart.setOption(option);
        },
        envChartReload(env, data){//环境信息重载
            this.envChart.setOption({
                legend: {
                    data: [env.name || '']
                },
                xAxis: {
                    data: data.reverse().map(item => item.examtime.substr(5, 5))
                },
                yAxis: {
                    name: env.unit || ''
                },
                series:[{
                    name: env.name || '',
                    data: data.map(item => {
                        return {
                            name: item.name,
                            value: item[env.envparamid]
                        }
                    })
                }]
            })
        },
        arrowClick(ref, val) {//上下切换
            if (val === "right") {
                this.$refs[ref].next();
            } else {
                this.$refs[ref].prev();
            }
        },
    },
};
</script>

<style scoped lang='scss'>
  .qyfb {
    display: flex;
    padding: 0 60px;
    box-sizing: border-box;
    .left {
      width: 560px;
      padding-top: 50px;
      box-sizing: border-box;
      position: relative;
    }
    .right {
      flex: 1;
      margin-left: 40px;
      display: flex;
      position: relative;
      margin-top: 70px;
      .title {
        position: absolute;
        width: 607px;
        height: 53px;
        background: url("./images/title-bg.png") no-repeat center/cover;
        top: -60px;
        left: 50%;
        transform: translateX(-300px);
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 53px;
        text-align: center;
      }
      .inner-left {
        flex: 1;
        .top {
          height: 360px;
          padding: 0 40px;
          box-sizing: border-box;
          .environment-info {
            height: 80px;
            margin: 10px 0;
            background: url('~@/assets/images/zsk_border.png') no-repeat center/100% 100%;
            position: relative;
            .arrow{
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;
              z-index: 4;
            }
            .left-arrow{
              left: 10px;
            }
            .right-arrow{
              right: 10px;
            }
            ::v-deep .el-carousel__button{
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #25B0FF;
            }
            .inner {
              display: flex;
              align-items: center;
              justify-content: space-around;
              margin-top: 14px;
              padding: 0 18px;
              box-sizing: border-box;
              .item {
                display: flex;
                flex-direction: column;
                > span:first-child {
                  font-size: 16px;
                  color: #fff;
                  margin-bottom: 8px;
                }
                > span:last-child {
                  font-size: 22px;
                  color: #0CFED9;
                }
              }
            }
          }
          .chart {
            height: 210px;
          }
        }
        .bottom {
          height: 440px;
          .video-wrapper {
            display: flex;
            padding: 30px 28px;
            box-sizing: border-box;
            height: 390px;
            .video-list {
              height: 330px;
              width: 150px;
              ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
              }
              .video-item {
                width: 149px;
                height: 32px;
                padding: 0 6px;
                background: rgba(36,100,180,0.6);
                border: 1px solid #2464B4;
                border-radius: 2px;
                line-height: 32px;
                text-align: center;
                font-size: 16px;
                box-sizing: border-box;
                cursor: pointer;
                margin-bottom: 24px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                &:hover,
                &.active {
                  background: rgba(0,87,82,0.6);
                  border: 1px solid #00FFEF;
                }
              }
            }
            .video {
              width: 536px;
            }
          }
        }
      }
      .inner-right {
        width: 420px;
        margin-left: 36px;
        .pub_border {
          height: 800px;
        }
        .company-info {
          display: flex;
          flex-direction: column;
          padding: 22px 16px;
          box-sizing: border-box;
          .info-item {
            font-size: 16px;
            color: #fff;
            margin-bottom: 22px;
            display: flex;
            align-items: center;
            span {
              min-width: 70px;
              margin-left: 20px;
            }
          }
          .description {
            font-size: 16px;
            line-height: 30px;
            height: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 20px;
          }
          .company-image {
            position: relative;
            .arrow{
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              cursor: pointer;
              z-index: 4;
            }
            .left-arrow{
              left:0;
            }
            .right-arrow{
              right: 0;
            }
            ::v-deep .el-carousel__button{
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #25B0FF;
            }
            .inner {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 30px;
            }
          }
          .company-device {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            .device-item {
              width: 50%;
              height: 95px;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              div {
                display: flex;
                flex-direction: column;
                > span:first-child {
                  color: #fff;
                  font-size: 18px;
                  margin-bottom: 14px;
                }
                > span:last-child {
                  color: #00A2FF;
                  font-size: 24px;
                }
              }
            }
          }
        }
      }
      .pub_border {
        background: linear-gradient(180deg, rgba(14,47,82,0), rgba(14,47,82,0.76));
      }
    }
  }

  .pub_legend {
    position: absolute;
    right: 40px;
    bottom: 60px;

    .legend_list {
      padding: 10px;
      box-sizing: border-box;

      >li {
        font-size: 16px;
        // border: 1px solid #0f0;
        height: 30px;
        display: flex;
        align-items: center;
        // padding-right: 0px;
        box-sizing: border-box;
        cursor: pointer;

        .pub_checkbox {
          margin-right: 10px;
        }

        img {
          margin-right: 10px;
          width: 21px;
        }
      }

      // >li:nth-child(7){
      //     img{
      //         position: relative;
      //         left: 5px;
      //     }
      // }
    }
  }
  .tolink {
    position: absolute;
    right: 0;
    top: 150px;
    cursor: pointer;
    background: url("../../assets/images/cityBg.png") no-repeat;
    background-size: 100% 70%;
    background-position: bottom;
    padding: 10px 20px;
  }
  .staticList {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    .item {
      margin: 0 10px;
      background: url("../../assets/images/qyfbItem.png") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 10px 30px;
      min-width: 140px;
      .value {
        font-weight: 400;
        font-size: 22px;
        margin-top: 4px;
        color: #FF9B45;
      }
    }
  }
  ::v-deep .el-carousel__indicators--horizontal {
    display: none;
  }
</style>
