<template>
  <div class="spjk">
    <div class="left">
      <map-chart
        ref="videoMapRef"
        height="800px"
        :chart-option="{
          layoutSize: '120%'
        }"
        has-drop
        @selectCity="selectCity"
      ></map-chart>
    </div>
    <div class="right">
      <div class="title">
        {{name}}
      </div>
      <div class="video-wrapper">
        <el-scrollbar class="video-list">
          <div class="video-item"
               :class="{'active': curVideo.includes(item.id)}"
               v-for="item in videoData"
               :key="item.id"
               :title="item.name"
               @click="videoChange(item)"
          >{{item.name}}</div>
        </el-scrollbar>
        <div id="playWnd" class="video"></div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import mapChart from '../../components/map/map'
import { getDeviceByArea } from '@/api/zhsc'
import { SUCCESS } from '../../utils/config'
import { initPlugin, preview, stopPreview, spjcDestory } from '@/utils/video1'

export default {
    components: { mapChart },
    data() {
        return {
            videoData: [],
            curVideo: [],
            name: ''
        }
    },
    mounted() {
        let scale = this.getScale()
        initPlugin({
            width: 994 * scale,
            height: 740 * scale,
            layout: '3x3'
        })
        this.init()
    },
    methods: {
        getScale() {
            let wh = window.innerHeight / 1080;
            let ww = window.innerWidth / 1920;
            return ww < wh ? ww : wh;
        },
        async init() {
            this.name = '张家口市'
            await this.getDeviceByArea('张家口市', '')
        },
        getDeviceByArea(city, area) {
            return getDeviceByArea({
                city,
                area,
                industry: '蔬菜',
                deviceType: 'video'
            }, {
                autp: 2,
                token: window.sessionStorage.token
            }).then(res => {
                if (res.type === SUCCESS) {
                    this.videoData = res.data
                    let data = res.data.map(item => {
                        let locpoint = JSON.parse(item.locpoint)
                        return {
                            value: [locpoint.lng, locpoint.lat],
                            ...item
                        }
                    })
                    this.$refs.videoMapRef.addMarker([{
                        name: '摄像头',
                        url: `image://${require('@/assets/images/spjc_marker.png')}`,
                        size: [39, 51],
                        data: data
                    }])

                    this.curVideo.length && stopPreview()
                    this.$nextTick(() => {
                        this.curVideo = []
                        // 加载实时视频
                        if (this.videoData.length) {
                            for (let i = 0;i < 9; i++) {
                                this.videoChange(this.videoData[i], i + 1)
                            }
                        }
                    })
                }
            })
        },
        selectCity(obj) {
            if (obj.parent && !obj.parent.includes('河北')) {
                this.getDeviceByArea(obj.parent, obj.name)
            } else if (obj.parent.includes('河北')) {
                this.getDeviceByArea(obj.name, '')
            } else if (obj.name.includes('河北')) {
                return
            } else {
                this.getDeviceByArea('', obj.name)
            }
            this.name = obj.name
        },
        videoChange(obj, wndId) {
            if (obj) {
                this.curVideo.push(obj.id)
                preview(obj.terminal, wndId)
            }
        }
    },
};
</script>

<style scoped lang='scss'>
  .spjk {
    display: flex;
    padding: 0 60px 0 40px;
    box-sizing: border-box;
    .left {
      width: 600px;
      margin-right: 40px;
      padding-top: 80px;
      box-sizing: border-box;
    }
    .right {
      flex: 1;
      position: relative;
      .title {
        position: absolute;
        width: 607px;
        height: 53px;
        background: url("./images/title-bg.png") no-repeat center/cover;
        top: 12px;
        left: 50%;
        transform: translateX(-300px);
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 53px;
        text-align: center;
      }
      .video-wrapper {
        display: flex;
        margin-top: 70px;
        box-sizing: border-box;
        padding-top: 20px;
        .video-list {
          width: 186px;
          height: 720px;
          margin-top: 20px;
          ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
          }
          .video-item {
            width: 186px;
            height: 36px;
            background: rgba(36,100,180,0.6);
            border: 1px solid #2464B4;
            border-radius: 2px;
            line-height: 34px;
            text-align: center;
            font-size: 20px;
            box-sizing: border-box;
            cursor: pointer;
            margin-bottom: 30px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            &:hover,
            &.active {
              background: rgba(0,87,82,0.6);
              border: 1px solid #00FFEF;
            }
          }
        }
        .video {
          flex: 1;
          /*padding-left: 40px;*/
          box-sizing: border-box;
        }
      }
    }
  }
</style>
